import { TimelineCard } from "./timeline-card"

export function Timeline() {
	return (
		<div className="container py-5">
			<div className="main-timeline-4 text-white">
				<TimelineCard direction="LEFT" content="Smart forms product pitch" date="March 2022" />

				<TimelineCard direction="RIGHT" content="Discussion and development started" date="May 2022" />

				<TimelineCard direction="LEFT" content="First release of Smart forms with traditional UI , minimal features and free usage" date="August 2022" />

				<TimelineCard direction="RIGHT" content="Smart forms renamed to 170Apps" date="August 2022" />

				<TimelineCard direction="LEFT" content="Halloween update of 170Apps" date="November 2022" />

				<TimelineCard direction="RIGHT" content="Seamless form builder release" date="November 2022" />

				<TimelineCard direction="LEFT" content="Live tracking of form actions release" date="November 2022" />

				<TimelineCard direction="RIGHT" content="Official version 1 release with complete UI redesign, Tier based subscription model and user management" date="January 2023" />

				<TimelineCard direction="LEFT" content="170Apps renamed to IQZApps" date="January 2023" />

				<TimelineCard direction="RIGHT" content="Production release for internal use" date="March 2023" />

				<TimelineCard direction="LEFT" content="IQZApps Primerica demo" date="May 2023" />

				<TimelineCard direction="RIGHT" content="Pega world - With raffle feature" date="June 2023" />

				<TimelineCard direction="LEFT" content="Pay per user subscription model and conditional logic release" date="September 2023" />

				<TimelineCard direction="RIGHT" content="Multilingual translation and storage manager feature release" date="February 2024" />

				<TimelineCard direction="LEFT" content="Authenticating forms submitted by end user, Custom thank you pages, Data access over Rest API.    COMING SOON...." date="March 2024" />
			</div>
		</div>
	)
}

interface TimelineProps {
  type: 'MAJOR' | 'MINOR';
  direction: 'LEFT' | 'RIGHT';
  content: string;
  date: string;
}

export function TimelineCard(props: Partial<TimelineProps>) {
  return (
    <div className={`timeline-4 ${props.direction === 'LEFT' ? 'left-4' : 'right-4'}`}>
      <div className={`card border-0 ${props.direction === 'LEFT' ? 'gradient-custom' : 'gradient-custom-4'}`}>
        <div className="card-body p-4">
          <i className="fas fa-brain fa-2x mb-3"></i>
          {/* {
            props.direction === 'LEFT'
            ? <h3 className="text-custom-color fw-light">{props.content}</h3>
            : <p className="text-custom-color mb-1">{props.content}</p>
          } */}
          <h3 className="text-custom-color fw-light">{props.content}</h3>
          <p className="fs-6 text-white-50 m-0">{props.date}</p>
        </div>
      </div>
    </div>
  )
}
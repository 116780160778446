import { Timeline } from './components/timeline/timeline';

function App() {
  return (
    <div className='container py-5'>
      <div className='fs-2 text-center fw-bold title-style'>IQZApps</div>
      <div className='fs-2 text-center fw-bold'>Our journey so far</div>
      <Timeline />
      <div className={`p-1 iframe-border`}>
        <iframe title="form" src="https://iqzapps.com/f/d9818502-f899-4e30-bc78-13ceb2403081?embed=iframe"
          allowTransparency allowFullScreen
          style={{ minWidth: '100%', height: '80vh', border: 'none' }}
        />
      </div>
    </div>
  );
}

export default App;
